.container {
  height: 50px;
  background: #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

.name {
  color: #0f9d58;
  font-size: 1.1rem;
  font-weight: bold;
}

.logout {
  background: #0f9d58;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.logout:hover {
  background: #07713e;
}
